'use client';
import { useEffect, useState } from 'react';
import { setCookie, getCookie } from 'cookies-next';
import Link from 'next/link';
import Button from '@/components/Button';
import { cookieConsentName } from '@/lib/constants';

type cookieConsentCookie = {
  name: typeof cookieConsentName;
  value: 'accepted' | 'rejected';
  maxAge: number;
};

const twelveMonthsInSeconds = 365 * 24 * 60 * 60;
const domain = process.env.NODE_ENV === 'development' ? 'localhost' : 'gradle.com';

const cookieConsentCookie: cookieConsentCookie = {
  name: `${cookieConsentName}`,
  value: 'accepted',
  maxAge: twelveMonthsInSeconds,
};

export function CookieConsentBanner() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consentGiven = getCookie(cookieConsentCookie.name);
    if (consentGiven === undefined) {
      setShowBanner(true);
    }
  }, []);

  const onAccept = () => {
    setCookie(cookieConsentCookie.name, cookieConsentCookie.value, {
      maxAge: twelveMonthsInSeconds,
      domain,
    });

    window.gtag('consent', 'update', {
      ad_storage: 'granted',
      // @ts-expect-error types wrong https://developers.google.com/tag-platform/gtagjs/reference#consent
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted',
    });

    setShowBanner(false);
  };

  const onReject = () => {
    setCookie(cookieConsentCookie.name, 'rejected', {
      maxAge: twelveMonthsInSeconds,
      domain,
    });

    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="fixed bottom-0 z-50 w-full bg-dark-background text-white">
      <div className="max-w-section align-center flex w-full flex-col justify-center gap-2 px-4 py-6 md:flex-row md:px-14 lg:gap-10">
        <p className="text-footnote">
          We&apos;d like to collect non-essential cookies for analytics and marketing which involves cookies managed by
          third parties. You can read more about how we use cookies in our{' '}
          <Link href="/legal/privacy/" className="underline">
            Privacy Policy
          </Link>
          . By clicking “Accept,” below you agree to our website&apos;s cookie use as described in our Privacy Policy
          and our collection of non-essential cookies.
        </p>
        <div className="flex gap-4">
          <Button className=" self-center" variant="ghost-light" outline onClick={onReject}>
            Reject
          </Button>
          <Button className=" self-center" variant="primary" onClick={onAccept}>
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
}
